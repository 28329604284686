@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&family=Irish+Grover&display=swap");

a {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

img {
  width: 100%;
}

body {
  font-family: "Inter", sans-serif;
}

.font-title {
  font-family: "Irish Grover", sans-serif;
}

.App {
  overflow: hidden;
}
